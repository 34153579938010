@font-face {
    font-family: 'geinspira';
    src: url('fonts/geinsprg-webfont.eot');
    src: url('fonts/geinsprg-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/geinsprg-webfont.woff') format('woff'),
         url('fonts/geinsprg-webfont.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'geinspira';
    src: url('fonts/geinspit-webfont.eot');
    src: url('fonts/geinspit-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/geinspit-webfont.woff') format('woff'),
         url('fonts/geinspit-webfont.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
}
@font-face {
    font-family: 'geinspira';
    src: url('fonts/geinspbd-webfont.eot');
    src: url('fonts/geinspbd-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/geinspbd-webfont.woff') format('woff'),
         url('fonts/geinspbd-webfont.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: 'geinspira';
    src: url('fonts/geinspbi-webfont.eot');
    src: url('fonts/geinspbi-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/geinspbi-webfont.woff') format('woff'),
         url('fonts/geinspbi-webfont.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
}
