// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

$body-bg: #FFF;
$body-color: #015db9;
$blue: #0d6efd;

$modal-sm: none;
$modal-md: none;
$modal-lg: none;
$modal-xl: none;

$modal-content-color: white;
$modal-content-bg: #005eb8;
$modal-content-border-color: #005eb8;
$modal-content-border-width: 0px;
$modal-content-border-radius: 0;
$modal-backdrop-opacity: 0;

$modal-fade-transform: scale(.8);
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs:       $box-shadow-sm !default;
// $modal-content-box-shadow-sm-up:    $box-shadow !default;

// 3. Include remainder of required Bootstrap stylesheets

// Configuration
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

@import "fonts.scss";
@import "app.scss";
