$ge-font: 'geinspira', Helvetica, Arial, sans-serif;
$scroll-color: #bbc3c4;
$sky-blue: #00b5e2;
$navy-blue: #13294b;

body {
  background-color: white;
  &.curtainsDrawn {
    background-color: $body-color;
  }
}

a {
  color: $sky-blue;
  &:hover {
    color: $navy-blue;
  }
  text-decoration: underline;
}

sup {
  top: -0.5em;
  padding-right: 0.3em;
}

.App {
  text-align: center;
  color: white;
  background-color: #fff;
  &.curtainsDrawn {
    background-color: $body-color;
    justify-content: flex-start;
  }
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Stage {
  height: 100%;
  width: 100%;
  position: relative;
}

.App .Stage .vjs-big-play-button {
  margin-left: 40%;
  margin-top: 20%;
}

// When embedded, we don't want curtains to show scroll bars.
@include media-breakpoint-down(lg) {
  .embeddedInCorporateSite {
    .Curtains {
      font-size: 0.9em;
      img.pi {
        height: 56%;
      }
      img.logo {
        height: 41px;
      }

      .Pill {
        height: 40px;
        padding-top: 12px;
        margin: 10px 165px 10px 165px;
        &.small {
          height: 40px;
          padding-top: 6px;
          margin-top: 22px;
        }
      }
      .date-stamp {
        height: 0px;
        .p-4 {
          position: relative;
          bottom: 38px;
        }
      }
    }
  }
}

.Curtains {
  @include media-breakpoint-down(sm) {
    font-size: 1.2em
  }
  background-color: $body-color;
  color: white;
  font-size: 1.3em;
  text-align: center;
  vertical-align: middle;
  width: 100vw;
  z-index: 100;
  a:hover {
    text-decoration: none;
  }
  .d-flex {
    padding: 2%;
  }
  .logo {
    width: 150px;
  }
  .pi {
    width: 55px;
  }
  .learn {
    font-size: 1.2em;
    @include media-breakpoint-down(xl) {
      font-size: 1.1em;
    }
    @include media-breakpoint-down(lg) {
      font-size: 0.9em;
    }
  }
  .Pill {
    background-color: $sky-blue;
    border-radius: 80px;
    color: white;
    height: 100px;
    margin-left: 10%;
    margin-right: 10%;
    margin: 10px 85px 10px 85px;
    @include media-breakpoint-down(sm) {
      font-size: 0.8em;
      margin: 10px 5px 10px 5px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 1em;
      br {
        display: none;
      }
    }
    padding-top: 33px;
    @include media-breakpoint-down(md) {
      font-size: 0.9em;
    }
    &.small {
      background-color: $body-color;
      border: 2px solid white;
      font-weight: bold;
      height: 60px;
      margin-top: 42px;
      margin-right: 90px;
      margin-left: 90px;
      min-width: 280px;
      padding-top: 17px;

      @include media-breakpoint-down(sm) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

.HotSpot {
  position: absolute;
  // background-color: #97e82a59;
  border-radius: 50%;
  height: 17%;
  width: 10%;
  z-index: 99;
  cursor: pointer;
}

#hs-pi {
  top: 0%;
  left: 93%;
  width: 7%;
  border-radius: unset;
}

#hs0 {
  top: 47%;
  left: 42.5%;
}

#hs1 {
  top: 42%;
  left: 69%;
}

#hs2 {
  top: 25.7%;
  left: 51.1%;
}

#hs3 {
  top: 41.6%;
  left: 46.3%;
}

#hs-pill0 {
  top: 41.8%;
  left: 26%;
  width: 45.5%;
  height: 9.4%;
  border-radius: 50px;
}

#hs-pill1 {
  top: 53.1%;
  left: 26%;
  width: 45.5%;
  height: 9.4%;
  border-radius: 50px;
}

#hs-pill2 {
  top: 64.7%;
  left: 26%;
  width: 45.5%;
  height: 9.4%;
  border-radius: 50px;
}

// Video JS Enhancements
#MainVideo {
  &.video-js {
    background-color: #fff !important;
    .vjs-picture-in-picture-control {
      display: none;
    }
  }
}

// Don't show fullscree toggle in Showpad mode
.embeddedInShowpad {
  #MainVideo {
    &.video-js {
      .vjs-fullscreen-control {
        display: none;
      }
    }
  }
}

.iv-hotspot-marker {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
}

.iv-time-tooltip {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 0.3em;
  color: #000;
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  padding: 6px 8px 8px 8px;
  pointer-events: none;
  position: absolute;
  right: -70px;
  top: -3.4em;
  visibility: hidden;
  z-index: 1;
}

.iv-dot:before {
  font-size: 0.9em;
  position: absolute;
  right: -0.5em;
  top: -0.3333333333em;
  z-index: 1;
  content: "\f111";
  font-family: VideoJS
}

#hsm0 {
  width: 25%;
}

#hsm1 {
  width: 35.5%;
}

#hsm2 {
  width: 41.25%;
}

#hsm3 {
  width: 65%;
}

// Modal
.modal-dialog {
  font-family: $ge-font;

  margin: 2.5%;
  width: 94%;

  @include media-breakpoint-up(xxl) {
    margin: 7.5%;
    width: 85%;
  }

  @media (min-width: 1800px) {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  background-color: $body-color;

  .modal-header {
    height: 15vh;
    @include media-breakpoint-up(xl) {
      height: 10vh;
    }
    @include media-breakpoint-down(xl) {
      height: unset;
      align-items: center;
    }
    font-size: 1.8em;
    font-weight: bold;
    align-items: end;
    padding-left: 5%;
    padding-right: 5%;
    button {
      padding-bottom: 44px;
      @include media-breakpoint-up(xl) {
        padding-bottom: 1px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 0px;
        padding-bottom: 1px;
      }
    }
    svg {
      width: 45px;
    }
  }

  .modal-title {
    background-color: $sky-blue;
    padding: 9px 19px 0px;
    font-weight: bold;
    h4 {
      font-size: 0.85em;
      font-weight: bold;
    }
  }

  .modal-body {
    padding-left: 5%;
    .container-fluid {
      padding-left: 0px;
    }

    min-height: 66vh;

    @include media-breakpoint-up(xl) {
      min-height: 57vh;
    }
    @include media-breakpoint-down(xl) {
      height: none;
      max-height: none;
    }
    .content-pane {
      p {
        line-height: 1.1em;
      }

      hr {
        height: 2px;
        background-color: $sky-blue;
      }

      .chunk {
        font-size: 1.4em;
        line-height: 1.3em;
        margin-bottom: 12px;
        @include media-breakpoint-down(xl) {
          font-size: 1.3em;
        }
      }

      .sky-blue {
        color: $sky-blue;
        font-weight: bold;
        font-size: 1.3em;
      }

      @include media-breakpoint-up(xl) {
        overflow-y: scroll;
        max-height: 47vh;
        // Fix for tablets in landscape
        width: 66%;
      }

      @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
        max-height: none;
      }

      scrollbar-color: white $navy-blue;
      padding-right: 7%;
      h3 {
        font-size: 2.6em;
        @include media-breakpoint-down(xs) {
          font-size: 1.6em;
        }
        font-weight: bold;
        line-height: 1.0em;
        margin-bottom: 0.9em;
      }
    }
    .safety-pane {
      // Fix for tablets in landscape
      @include media-breakpoint-up(xl) {
        width: 34%;
        max-height: 47vh;
      }

      overflow-y: auto;

      padding-left: 3%;
      padding-right: 3%;

      @include media-breakpoint-down(xl) {
        padding-left: 1%;
        padding-right: 0%;
      }
      @include media-breakpoint-down(xxl) {
        padding-left: 3%;
        padding-right: 0%;
      }
      li, p {
        line-height: 20px;
        font-size: 16px;
      }
      ol.hs0 {
        li, p {
          line-height: 16px;
          font-size: 12px;
        } 
      }
    }
  }

  font {
    p-size: 1.2em;
  }
  .echocardiograms {
    > .row {
      margin-top: 43px;
    }
    .area {
      img {
        max-width:100%;
        min-width:100%;
      }
      .enhanced {
        margin-top: 50px;
        @include media-breakpoint-down(xxl) {
          margin-top: 5px;
        }
        h5 {
          background-color: $sky-blue;
        }
      }
      section {
        padding-bottom: 33px;
      }
    }
    h4 {
      font-size: 1.1em;
      font-weight: bold;
      padding-bottom: 12px;
      height: 42px;
      width: 200%;
      @include media-breakpoint-down(xxl) {
        width: 100%;
      }
    }
    h5 {
      background-color: #bbc3c4;
      color: white;
      font-size: 1.1em;
      font-weight: bold;
      height: 34px;
      margin-bottom: 0;
      padding-left: 12px;
      padding-top: 6px;
    }
  }

  .bump-bar {
    height: 11vh;
    @include media-breakpoint-up(xl) {
      height: 7vh;
    }

    padding-top: 3vh;
    .scroll {
      button {
        svg {
          width: 60px;
        }
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    .bump-bar {
      display: none;
    }
  }

  .modal-footer {
    height: 5vh;
    min-height: 53px;
    padding-left: 3.7%;
    @include media-breakpoint-down(xl) {
      padding-left: 2.9%;
    }

    justify-content: flex-start;
    &.back {
      .btn-back {
        color: white;
        font-size: 1.2em;
        font-weight: bold;
        margin-top: -5px;
        svg {
          width: 12px;
          margin-right: 4px;
          margin-bottom: 2px;
        }
      }
      background-color: $navy-blue;
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: $scroll-color;
  border: solid 3px $modal-content-bg;
}

::-webkit-scrollbar-thumb {
  background-color: $scroll-color;
}
